<template>
  <div>
    <Loader :loading="loading" />

    <div
      v-if="!loading"
      class="hero-pattern min-h-screen flex flex-col justify-between items-center"
    >
      <parcelex-business-header />
      <div class="flex-shrink-0 p-6 lg:p-0 w-full md:max-w-md">
        <div class="p-6 lg:p-9 lg:pb-8 bg-white rounded-2xl mb-6 shadow-lg">
          <form
            v-on:submit.prevent="handleChangePassword"
            class="login-wrapper"
            :class="{ mobile }"
          >
            <header>
              <h1 class="font-bold text-xl mb-4">Cadastrar nova senha</h1>
              <p class="text-gray-500 mb-3">
                Por segurança, sua nova senha deve ser diferente da sua senha anterior.
              </p>
            </header>

            <Input
              label="Senha"
              class="mb-5 input-password"
              type="password"
              :class="[{ mobile }, { error: getMessage('password') }, 'mb-6']"
              @input="formHandler('password', ...arguments)"
              :errorMessage="getMessage('password')"
            />

            <Input
              label="Confirmar senha"
              type="password"
              class="input-password"
              :class="[{ mobile }, { error: getMessage('repeatPassword') }, 'mb-6']"
              @input="formHandler('repeatPassword', ...arguments)"
              :errorMessage="getMessage('repeatPassword')"
            />

            <Button class="button button-primary w-full mb-6">
              Confirmar nova senha
            </Button>
          </form>
        </div>
      </div>
      <footer class="text-center text-primary-500 pt-4 pb-6">
        &copy; Parcelex - {{ actualDate }}
      </footer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import Input from '@/components/ui/AppInput.vue';
import Button from '@/components/ui/AppButton.vue';
import UtilMixin from '@/mixins/util';
import Loader from '@/components/common/Loader.vue';
import ParcelexBusinessHeader from '../../../components/common/ParcelexBusinessHeader.vue';
import isMobile from '../../../lib/isMobile';

export default {
  name: 'change-password',
  metaInfo: {
    title: 'Para Negócios',
  },
  data: () => ({
    mobile: false,
    loading: false,
    username: '',
    formInputs: {
      password: {
        currentValue: undefined,
        isValid: false,
        validateFunction: 'isValidPassword',
        errorMessage: 'Senha inválida',
        currentMessage: '',
      },
      repeatPassword: {
        currentValue: undefined,
        isValid: false,
        validateFunction: 'isPasswordRepeatCorrect',
        errorMessage: 'Senhas não conferem',
        currentMessage: '',
      },
    },
  }),
  mixins: [UtilMixin],
  components: {
    Input,
    Button,
    ParcelexBusinessHeader,
    Loader,
  },
  computed: {
    isValidForm() {
      let isValid = true;
      const keys = Object.keys(this.formInputs);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        if (!this.formInputs[key].isValid) {
          isValid = false;
          break;
        }
      }
      return isValid;
    },
    actualDate() {
      return new Date().getFullYear();
    },
  },
  methods: {
    ...mapActions('auth', ['changePassword']),
    ...mapMutations('auth', ['setToken']),
    async handleChangePassword() {
      const newPassword = this.formInputs.password.currentValue;
      const confirmNewPassword = this.formInputs.repeatPassword.currentValue;
      if (newPassword !== confirmNewPassword) {
        this.formInputs.repeatPassword.currentMessage = 'As senhas são diferentes';
        return;
      }
      if (newPassword.length < 4) {
        this.formInputs.repeatPassword.currentMessage = 'Sua senha precisa ter pelo menos 4 caracteres';
        return;
      }

      this.loading = true;

      await this.changePassword({
        username: this.username,
        newPassword,
      });
    },
    formHandler(type, value) {
      if (!value) return;

      const { validateFunction } = this.formInputs[type];
      this.formInputs[type].isValid = this[validateFunction](value);
      this.formInputs[type].currentValue = value;

      if (this.formInputs[type].currentMessage !== '') {
        this.formInputs[type].currentMessage = '';
      }
    },
    errorMessageHandler() {
      Object.keys(this.formInputs).forEach((key) => {
        if (!this.formInputs[key].isValid) {
          this.formInputs[key].currentMessage = this.formInputs[key].errorMessage;
        }
      });
    },
    getMessage(type) {
      return this.formInputs[type].currentMessage;
    },
    isValidPassword(password) {
      return password && password.length > 3;
    },
    isPasswordRepeatCorrect(repeatPassword) {
      return repeatPassword === this.formInputs.password.currentValue;
    },
  },
  mounted() {
    const { authToken, authUser } = this.$route.query;
    if (!authToken) this.$router.push('/login');

    this.setToken({ token: authToken });
    this.username = authUser;

    this.mobile = isMobile();
  },
};
</script>
